import { parseCookies } from 'nookies';
import { parseDomain } from 'parse-domain';
import { dig } from '../../helpers/dig';
import { identify } from '../../client/segment';
import { analyticsClient } from '../../client/analytics';

export const USE_CASES = {
  abm: 'abm',
  websiteConversion: 'increase_website_cvr',
  landingPageConversion: 'increase_lp_cvr',
};

export const QUALIFICATIONS = {
  strong: 'strong',
  weak: 'weak',
  unqualified: 'unqualified',
};

export const VISITOR_OPTIONS = {
  under10k: 'less_than_10k',
  under50k: '10_to_50k',
  under250k: '50k_to_250k',
  over250k: 'over_250k',
};

const UNQUALIFIED_VISITORS_COUNTS = [VISITOR_OPTIONS.under10k];
const WEAK_VISITORS_COUNT = VISITOR_OPTIONS.under50k;
const B2B_TAG = 'B2B';

const NON_QUALIFYING_SIZES = ['1-10', '11-50'];
export function meetsSkipStepRequirements(enrichResult) {
  const numEmployees = dig(enrichResult, 'company', 'metrics', 'employeesRange');
  return numEmployees && !NON_QUALIFYING_SIZES.includes(numEmployees);
}

export function getQualification(enrichResult, monthlyVisitors, useCase) {
  const isB2b = (dig(enrichResult, 'company', 'tags') || []).includes(B2B_TAG);
  const employeeRequirement = meetsSkipStepRequirements(enrichResult);
  const isStrong = (isB2b && employeeRequirement) || !UNQUALIFIED_VISITORS_COUNTS.includes(monthlyVisitors);
  const isWeak =
    (!isB2b && employeeRequirement) || (monthlyVisitors === WEAK_VISITORS_COUNT && useCase === USE_CASES.abm);
  return isStrong ? QUALIFICATIONS.strong : isWeak ? QUALIFICATIONS.weak : QUALIFICATIONS.unqualified;
}

export function getValueKeyForStep(step) {
  return ['email', 'domain', 'useCase', 'monthlyVisitors'][step - 1];
}

export const deleteEmptyValues = (obj) => {
  const newObj = {};
  Object.entries(obj).forEach(([key, value]) => {
    if (value && value !== '') {
      newObj[key] = value;
    }
  });
  return newObj;
};

export const domainForm = (url) => {
  const parsed = parseDomain(url);
  if (parsed && parsed.domain && parsed.tld) {
    return `${parsed.domain}.${parsed.tld}`;
  }
  return null;
};

export function completeEmailStep(email, userState, source) {
  const sourceCookie = parseCookies()['mutiny.source'];
  identify(email, {
    email,
    ...deleteEmptyValues({
      contact_lead_source: sourceCookie,
      referrer_url: userState.referrer,
    }),
  });
  analyticsClient.identify(email, {
    email,
    clearbitRevealResult: userState.revealResult,
    ...deleteEmptyValues({
      contact_lead_source: sourceCookie,
      referrer_url: userState.referrer,
    }),
  });
  analyticsClient.track('[Signup Form] Email completed', {
    email,
    source,
    ...deleteEmptyValues({
      lead_source: sourceCookie,
    }),
  });
}

export function completeDomainStep(domain, formState) {
  const isPrefilled = formState.foundEmailMatch;
  const wasChanged = dig(formState.latestEnrichResult, 'company', 'domain') !== domainForm(domain);
  analyticsClient.track('[Signup Form] Domain completed', {
    domain,
    prefilled: isPrefilled ? (wasChanged ? 'prefilled-changed' : 'prefilled') : 'empty',
  });
}

export function completeUseCaseStep(useCase) {
  analyticsClient.track('[Signup Form] Use Case completed', {
    useCase,
  });
}

export function completeMonthlyVisitorsStep(monthlyVisitors, skipped) {
  analyticsClient.track('[Signup Form] Monthly Visitors completed', {
    monthlyVisitors,
    skipped,
  });
}

export function completeBooking(qualification, skipped) {
  analyticsClient.track('[Signup Form] Calendly completed', {
    contact_pre_qualification: qualification,
    skipped,
  });
}

export const EMAIL_REGEX = /\S+@\S+\.\S+/;
