import Head from 'next/head';

const SeoMeta = (props) => {
  const { domain, title, ogTitle, description, url, image } = props;

  return (
    <Head>
      {/* HTML Meta Tags */}
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {/* Facebook Meta Tags */}
      {domain && <meta property="og:type" content="website" />}
      {ogTitle && <meta property="og:title" content={ogTitle} />}
      {url && <meta property="og:url" content={url} />}
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={image} />}
      {/* Twitter Meta Tags */}
      {domain && <meta name="twitter:card" content="summary_large_image" />}
      {domain && <meta property="twitter:domain" content={domain} />}
      {url && <meta property="twitter:url" content={url} />}
      {ogTitle && <meta name="twitter:title" content={ogTitle} />}
      {description && <meta name="twitter:description" content={description} />}
      {image && <meta name="twitter:image" content={image} />}
    </Head>
  );
};

export default SeoMeta;
