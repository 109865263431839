// Hooks
import { useRef, useEffect, useContext } from 'react';
import { useInView } from 'react-intersection-observer';

// Next
import Router from 'next/router';
import Link from 'next/link';
import Button from '../Button/Button';

// Context
import { FormContext } from '../../context/FormContext';
import { UserContext } from '../../context/UserContext';

// Form Utils
import { completeEmailStep, EMAIL_REGEX } from '../Form/helpers';

// Analytics
import { dig } from '../../helpers/dig';
import { triggerWithTimeout, enrich } from '../../client/clearbit';
import { analyticsClient } from '../../client/analytics';
import { setCookie } from '../../helpers/cookie';

const PUBLIC_EMAIL_COOKIE_KEY = 'mutinyEmail';

const RequestInvite = (props) => {
  const { defaultLabel, modifier, buttonURL, buttonLabel, name, textlink, source } = props;
  const formState = useContext(FormContext);
  const userState = useContext(UserContext);

  const RequestInviteStyle = modifier ? `RequestInvite ${modifier}` : 'RequestInvite';
  let ButtonStyle =
    modifier === 'RequestInvite--homeHeader'
      ? 'Button--electricPurpleFill'
      : modifier === 'RequestInvite--deepPurple'
      ? 'Button--deepPurple'
      : 'Button--white';

  ButtonStyle = modifier === 'RequestInvite--electricPurple' ? 'Button--electricPurpleFill' : ButtonStyle;

  const refContainer = useRef();
  const refInnerWrapper = useRef();
  const refInputContainer = useRef();
  const refInput = useRef();

  useEffect(() => {
    const focusEventListener = () => {
      refInputContainer.current.classList.add('is-focused');
    };
    const blurEventListener = () => {
      if (refInputContainer.current != null) {
        refInputContainer.current.classList.remove('is-focused');
      }
    };

    if (refInput.current != null) {
      refInput.current.addEventListener('focus', focusEventListener);
      refInput.current.addEventListener('blur', blurEventListener);
    }

    // show request invite (with delay so you don't see jump)
    refContainer.current.classList.add('is-visible');

    return () => {
      if (refInput.current) {
        refInput.current.removeEventListener('focus', focusEventListener);
        refInput.current.removeEventListener('blur', blurEventListener);
      }
    };
  }, []);

  // intersection observer
  const [refMainWrapper, inView] = useInView({
    threshold: 1, // check if treshold works correct in firefox
    rootMargin: '0px 0px -50px 0px',
  });

  useEffect(() => {
    // only auto focus input field on desktop
    if (window.innerWidth >= 1024) {
      if (inView) {
        setTimeout(() => {
          // if (refInput.current !== null) refInput.current.focus();
        }, 1000);
      } else {
        // refInput.current.blur();
      }
    }
  }, [inView]);

  const handleClick = (e) => {
    e.preventDefault();
    const email = refInput.current.value;
    const validEmail = EMAIL_REGEX.test(email);
    analyticsClient.track(`Request Invite (${name}) clicked`, {
      email,
      validEmail,
    });

    const params = { source };
    const redirectUrl = [
      buttonURL,
      Object.keys(params)
        .filter((field) => params[field])
        .map((field) => `${field}=${encodeURIComponent(params[field])}`)
        .join('&'),
    ]
      .filter((urlPart) => urlPart)
      .join('?');

    if (validEmail) {
      setCookie(PUBLIC_EMAIL_COOKIE_KEY, email, 10);
      formState.setPreEnteredEmail(email);
      completeEmailStep(email, userState, name);
      triggerWithTimeout(
        () => enrich(email),
        () => Router.push(redirectUrl),
        (result) => {
          formState.setLatestEnrichResult(result);
          Router.push(redirectUrl);
        },
        (result) => {
          formState.setFoundEmailMatch(true);
          analyticsClient.track('Clearbit email enriched', {
            email,
            domain: dig(result, 'company', 'domain'),
            source: name,
          });
        },
      );
      formState.setStep(2);
    } else {
      Router.push(redirectUrl);
    }
  };

  const revealedDomain = userState.revealResult && userState.revealResult.domain;
  const loadingReveal = userState.loadingReveal;

  // this ensures the placeholder doesn't change if reveal completes
  const defaultPlaceholder = loadingReveal ? '' : defaultLabel;

  return (
    <form className={RequestInviteStyle} ref={refMainWrapper} onSubmit={handleClick}>
      <div className="RequestInvite-container" ref={refContainer}>
        <div className="RequestInvite-inner" ref={refInnerWrapper}>
          <div className="RequestInvite-inputContainer" ref={refInputContainer}>
            <input
              type="text"
              placeholder={revealedDomain ? `you@${revealedDomain}` : defaultPlaceholder}
              autoCapitalize="off"
              ref={refInput}
            />
          </div>
          <div className="RequestInvite-buttonContainer" onClick={handleClick}>
            <Button label={buttonLabel} modifier={ButtonStyle} />
          </div>
        </div>
        {textlink && (
          <Link href={textlink.url}>
            <a className="RequestInvite-textLink">
              <span className="RequestInvite-textLinkLabel">{textlink.label}</span>
              <svg className="RequestInvite-textLinkIcon">
                <use xlinkHref="#arrow-next" />
              </svg>
            </a>
          </Link>
        )}
      </div>
    </form>
  );
};

export default RequestInvite;
