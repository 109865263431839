export function getDomainWithoutSubdomain() {
  let hostname = process.env.HOST_NAME;

  if (!hostname.startsWith('http')) {
    hostname = `https://${hostname}`;
  }

  const urlParts = new URL(hostname).hostname.split('.');

  return urlParts
    .slice(0)
    .slice(-(urlParts.length === 4 ? 3 : 2))
    .join('.');
}
