export default function getSeoData(props, imageURL) {
  const titleSuffix = 'Mutiny | Turn Your Website Into Your #1 Revenue Channel';
  const descriptionFallback =
    'Mutiny is a no-code AI platform that helps marketers convert their top of funnel demand into revenue, without engineers.';

  const title = props.seo?.fields?.title
    ? `${props.seo?.fields?.title}`
    : props.title
    ? `${props.title} - ${titleSuffix}`
    : titleSuffix;
  const metaDescription = props.seo?.fields?.metaDescription || descriptionFallback;

  const data = {};
  data.title = title || false;
  data.ogTitle = props.seo?.fields?.title ? props.seo?.fields?.title : props.title ? props.title : titleSuffix;
  data.description = metaDescription || false;
  data.domain = 'https://www.mutinyhq.com/';
  data.url = typeof window !== 'undefined' ? window.location.href : '';
  data.image = imageURL || false;

  return Object.keys(data).length ? data : false;
}
