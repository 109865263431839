// Hooks
import { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

// Contentful
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

// Components
import RequestInvite from '../RequestInvite/RequestInvite';
import NewsletterSubscribe from '../NewsletterSubscribe/NewsletterSubscribe';

const CallToAction = (props) => {
  const { title, highlight, bodyText, buttonLabel, style, buttonStyle, buttonAction, source } = props;
  const refTitle = useRef();

  const [callToAction, setCallToAction] = useState('');
  const callToActionInnerHTML = { __html: callToAction };

  // intersection observer
  const [titleContainer, inView] = useInView({
    threshold: 0,
    rootMargin: '0px 0px -200px 0px',
  });

  const titleStyle = inView ? 'CallToAction-title is-visible' : 'CallToAction-title';

  useEffect(() => {
    let tempString = '';

    if (highlight === '' || title.indexOf(highlight) === -1) {
      // use just the title if highlight is empty or does not occur in title
      tempString = title;
    } else {
      // split title by highlight
      const titleRest = title.split(highlight);

      if (titleRest[0] === '') {
        // highlight at start of title
        tempString = `<span class="CallToAction-titleHighlight">${highlight}</span>${titleRest[1]}`;
      } else if (titleRest[1] === '') {
        // highlight at end of title
        tempString = `${titleRest[0]}<span class="CallToAction-titleHighlight">${highlight}</span>`;
      } else {
        // highlight in middle of title
        tempString = `${titleRest[0]}<span class="CallToAction-titleHighlight">${highlight}</span>${titleRest[1]}`;
      }
    }

    setCallToAction(tempString);
  }, []);

  return (
    <div className={style}>
      <div className="CallToAction-titleContainer" ref={titleContainer}>
        <h1 className={titleStyle} ref={refTitle} dangerouslySetInnerHTML={callToActionInnerHTML} />
      </div>

      {bodyText && <div className="CallToAction-body">{documentToReactComponents(bodyText)}</div>}
      {buttonAction === 'requestInvite' && (
        <RequestInvite
          name="home-page-footer"
          defaultLabel="you@email.com"
          buttonLabel={buttonLabel}
          buttonURL="/request-invite"
          modifier={buttonStyle}
          source={source}
        />
      )}
      {buttonAction === 'subscribe' && (
        <>
          <NewsletterSubscribe defaultLabel="you@email.com" buttonLabel={buttonLabel} modifier={buttonStyle} />
        </>
      )}
    </div>
  );
};

export default CallToAction;
