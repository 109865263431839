import 'whatwg-fetch';

function fetchLambda(func, body) {
  return fetch(`${process.env.LAMBDA_API_HOST}.netlify/functions/segment`, {
    method: 'POST',
    headers: {
      Authorization: `Basic ${btoa(`${process.env.LAMBDA_AUTH_TOKEN}:`)}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      func,
      body,
    }),
  });
}

export function identify(email, traits) {
  return fetchLambda('identify', {
    userId: email,
    traits,
  });
}

export function group(email, domain, traits) {
  return fetchLambda('group', {
    userId: email,
    groupId: domain,
    traits,
  });
}
