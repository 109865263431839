import generateColorModifier from '../generateColorModifier';

export default function getCallToActionData(props) {
  const { title, highlightedText, text, requestInviteButtonLabel, colorModifiers, buttonAction } = props.fields;

  // Match variables with contentful fields
  const modifier = generateColorModifier('CallToAction', colorModifiers[0]);
  const action = buttonAction === 'Request invite' || !buttonAction ? 'RequestInvite' : 'NewsletterSubscribe';

  let requestInviteStyle = `${action}--deepPurple`;

  if (modifier === 'CallToAction--deepPurple' || modifier === 'CallToAction--electricPurple') {
    requestInviteStyle = `${action}--white`;
  }
  if (modifier === 'CallToAction--transparent' || modifier === 'CallToAction--white') {
    requestInviteStyle = `${action}--electricPurple`;
  }

  const data = {};
  data.title = title;
  data.highlight = highlightedText;
  data.modifier = modifier;
  data.bodyText = text;
  data.buttonLabel = requestInviteButtonLabel || 'Request invite';
  data.style = modifier ? `CallToAction ${modifier}` : 'CallToAction';
  data.buttonStyle = requestInviteStyle;
  data.buttonAction = buttonAction === 'Subscribe to newsletter' ? 'subscribe' : 'requestInvite';

  return Object.keys(data).length ? data : false;
}
