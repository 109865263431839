// Hooks
import { useRef, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

// Components
import Button from '../Button/Button';

// Utils
import handleNewsletterSubscribe from '../../utils/handleNewsletterSubscribe';
import validateEmail from '../../utils/validateEmail';

const NewsletterSubscribe = (props) => {
  const { modifier, buttonLabel } = props;

  const [formSuccessState, setFormSuccessState] = useState(false);

  const NewsletterSubscribeStyle = modifier ? `NewsletterSubscribe ${modifier}` : 'NewsletterSubscribe';
  let ButtonStyle =
    modifier === 'NewsletterSubscribe--homeHeader'
      ? 'Button--electricPurpleFill'
      : modifier === 'NewsletterSubscribe--deepPurple'
      ? 'Button--deepPurple'
      : 'Button--white';

  ButtonStyle = modifier === 'NewsletterSubscribe--electricPurple' ? 'Button--electricPurpleFill' : ButtonStyle;

  const refInnerWrapper = useRef();
  const refInputContainer = useRef();
  const refInput = useRef();

  useEffect(() => {
    if (formSuccessState && !refInputContainer.current) {
      return {};
    }
    const focusEventListener = () => {
      refInputContainer.current.classList.add('is-focused');
      refInputContainer.current.classList.remove('is-invalid');
    };
    const blurEventListener = () => {
      // Input might already be hidden as success state is shown
      if (refInputContainer.current !== null) {
        refInputContainer.current.classList.remove('is-focused');
      }
    };

    if (refInput.current != null) {
      refInput.current.addEventListener('focus', focusEventListener);
      refInput.current.addEventListener('blur', blurEventListener);
    }

    // show newsletter subscribe (with delay so you don't see jump)
    refInnerWrapper.current.classList.add('is-visible');

    return () => {
      if (refInput.current) {
        refInput.current.removeEventListener('focus', focusEventListener);
        refInput.current.removeEventListener('blur', blurEventListener);
      }
    };
  }, []);

  // intersection observer
  const [refMainWrapper, inView] = useInView({
    threshold: 1, // check if threshold works correct in firefox
    rootMargin: '0px 0px -50px 0px',
  });

  useEffect(() => {
    if (formSuccessState) {
      return;
    }
    // only auto focus input field on desktop
    if (window.innerWidth >= 1024) {
      if (inView) {
        setTimeout(() => {
          // if (refInput.current !== null) refInput.current.focus();
        }, 1000);
      } else {
        // refInput.current.blur();
      }
    }
  }, [inView]);

  const handleClick = (e) => {
    e.preventDefault();

    // reflow trick to be able to remove and add class in succession
    const reflow = () => refInputContainer.current.offsetHeight;

    const email = refInput.current.value;

    let validated = true;

    // Validate email
    if (!validateEmail(email)) {
      refInputContainer.current.classList.remove('is-invalid');
      reflow();
      refInputContainer.current.classList.add('is-invalid');
      validated = false;
    } else {
      refInputContainer.current.classList.remove('is-invalid');
    }

    if (validated) {
      handleNewsletterSubscribe(email);
      setFormSuccessState(true);
    }
  };

  const handleInput = () => {
    // when in error state validate while user is updating input
    if (refInputContainer.current.classList.contains('is-invalid')) {
      if (!validateEmail(refInput.current.value)) {
        refInputContainer.current.classList.add('is-invalid');
      } else {
        refInputContainer.current.classList.remove('is-invalid');
      }
    }
  };

  return (
    <form className={NewsletterSubscribeStyle} ref={refMainWrapper} onSubmit={handleClick}>
      <div className="NewsletterSubscribe-inner" ref={refInnerWrapper}>
        {!formSuccessState && (
          <>
            <div className="NewsletterSubscribe-inputContainer" ref={refInputContainer}>
              <input type="text" placeholder="you@email.com" ref={refInput} onChange={handleInput} />
            </div>
            <div className="NewsletterSubscribe-buttonContainer" onClick={handleClick}>
              <Button label={buttonLabel} modifier={ButtonStyle} />
            </div>
          </>
        )}
        {formSuccessState && (
          <div className="NewsletterSubscribe-success">
            Waitlist spot secured! You'll get an email shortly with next steps for joining M2.
          </div>
        )}
      </div>
    </form>
  );
};

export default NewsletterSubscribe;
