import { identify } from '../client/segment';

export default function handleNewsletterSubscribe(email) {
  identify(email, {
    conversion_secrets_subscription: true,
  });
}

export function handleEmailCapture(email) {
  identify(email);
}
